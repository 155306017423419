<template>
  <div v-if="shadeConfig.show" class="shade flex0">
    <div class="shade-section tc">
      <div v-if="shadeConfig.title" class="shade-section-title" v-html="shadeConfig.title"></div>
      <div v-if="shadeConfig.text" class="shade-section-text"  v-html="shadeConfig.text"></div>
      <div class="shade-section-btn">
        <div v-if="shadeConfig.btnType == 'link'" class="flex10 pt15">
          <button class="xy-btn mb" v-for="(item, index) in shadeConfig.btnList" :key="index" @click="operation(item.link)">{{ item.text }}</button>
        </div>
        <div v-if="shadeConfig.btnType == 1">
          <button class="xy-btn" :loading="btnLoad"  @click="operate(shadeConfig.btnType)">{{ shadeConfig.btnText }}</button>
        </div>
      </div>
      <i v-if="shadeConfig.closeIcon" class="el-icon-circle-close close-alert co-fff cur" @click="closeAlert"></i>
    </div>
  </div>
</template>

<script>
import { UpdateUserType } from "@/api/profile"
import { common_msg_update } from "@/utils/common"
import { mapState } from "vuex"

export default {
  name: 'alertBox',
  data() {
    return {
      btnLoad: false
    }
  },
  props: ['shadeConfig'],
  methods: {
    // !关闭弹窗
    closeAlert() {
      this.$emit('closeAlert', false)
    },
    // !按钮跳转操作
    operation(path) {
      this.$router.push(path)
    },
    // !接口调用 1(成为剑盟会员)
    operate(btnType) {
      this.btnLoad = true
      if (btnType === 1) {
        this.btnLoad = false
        UpdateUserType().then(res => {
          if (res.status === 1) {
            this.$toast.success('申请成功！')
            common_msg_update();
            this.$emit('closeAlert', false)
          } else {
            this.btnLoad = false
            this.toast(res.message)
          }
        })
      }
    }
  },
  computed: {
    ...mapState(['clientWidth'])
  }
}
</script>

<style lang="scss" scoped>
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  @media (min-width: 751px) {
    .shade-section { width: 450px; background: url('~@/assets/PC/InnovationContest/invite-friends.png') no-repeat; background-size: 100% 100%; padding: 90px 20px 40px 20px; position: relative; min-height: 300px }
    .shade-section-title { font-size: 24px; line-height: 1.5; margin-bottom: 15px }

    .xy-btn { padding: 12px 20px; font-size: 14px; background: #6b18a8; color: #fff; display: inline-block; border: 0; border-radius: 5px; cursor: pointer; transition: all 0.3s }
    .xy-btn+.xy-btn { margin-left: 10px }
    .xy-btn:hover { opacity: 0.8 } 
  }

  @media (max-width: 750px) {
    .shade-section { width: calc(100% - 20px); background: url('~@/assets/PC/InnovationContest/invite-friends.png') no-repeat; background-size: 100% 100%; padding: 90px 10px 40px 10px; position: relative; min-height: vw(300) }
    .shade-section-title { font-size: vw(40); line-height: 1.5; margin-bottom: 15px }

    .xy-btn { padding: 9px 15px; font-size: 12px; background: #6b18a8; color: #fff; display: inline-block; border: 0; border-radius: 3px }
    .xy-btn+.xy-btn { margin-left: 5px }
  }

</style>