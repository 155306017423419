<template>
  <div class="MarketingCenter">
    <div v-loading="loading"></div>
    <div class="banner">
      <img src="@/assets/PC/MarketingCenter/banner.png" class="banner" alt="">
      <img src="@/assets/PC/MarketingCenter/banner_m.png" class="banner_m" alt="">
    </div>
    <div class="main">
      <div class="main_content">
        <div class="intro">为了帮助合作伙伴快速掌握并获取营销资源，剑盟社区精心打造了一体化营销中心。通过共享设计模板、图片、活动宣传周边等丰富资源，致力于提升合作伙伴的营销专业能力，增强用户的信任感和品牌曝光率，进而有效促进商业机会的转化。</div>
        <div class="tab">
          <div class="tabtitle">
            <div class="tabtitleitem" v-for="(item, index) in tabnav" :key="index" @click="tabClick(index)" :class="{active:currenttab == index}">{{item.name}}</div>
          </div>
          <div class="tabcontent">
            <div class="tabcontentitem">
              <div class="left">
                <div class="filter">
                  <div class="filteritem" :class="{active: tabnav[currenttab].currentfilter == -1}" @click="filterClick(-1)">全部</div>
                  <div class="filteritem" @click="filterClick(index)" v-for="(item, index) in tabnav[currenttab].children" :key="index" :class="{active:tabnav[currenttab].currentfilter == index}">{{item.name}}</div>
                  <!-- <div class="nocategory" v-if="tabnav[currenttab].children.length == 0">暂无分类</div> -->
                </div>
                <div class="filter_m">
                  <span class="" @click="showfilterclick">分类
                    <i class="el-icno el-icon-arrow-down" :class="{active: tabnav[currenttab].showfilter}"></i>
                  </span>
                  <div class="filter_m_content" v-show="tabnav[currenttab].showfilter">
                    <div class="filteritem" :class="{active: tabnav[currenttab].currentfilter == -1}" @click="filterClick(-1)">全部</div>
                    <div class="filteritem" @click="filterClick(index)" v-for="(item, index) in tabnav[currenttab].children" :key="index" :class="{active:tabnav[currenttab].currentfilter == index}">{{item.name}}</div>
                    <!-- <div class="nocategory" v-if="tabnav[currenttab].children.length == 0">暂无分类</div> -->
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="list">
                  <div class="listtitle" v-if="tabnav[currenttab].currentfilter != -1">{{tabnav[currenttab].children[tabnav[currenttab].currentfilter].name}}<br>
                    <span>{{tabnav[currenttab].children[tabnav[currenttab].currentfilter].nameEN}}</span>
                  </div>
                  <div class="listcontent" v-if="tabnav[currenttab].listdata.data.length != 0">
                    <div class="listitem" v-for="(item, index) in tabnav[currenttab].listdata.data" :key="index">
                      <div class="item_left">
                        <img :src="item.banner" alt="">
                      </div>
                      <div class="item_right">
                        <div class="right_left">
                          <div class="item_title">{{item.title}}</div>
                          <div class="some">
                            <div v-if="item.sku.split(',')[0]">尺寸：{{item.sku.split(',')[0]}}</div>
                            <div v-if="item.sku.split(',')[1]">文件大小：{{item.sku.split(',')[1]}}</div>
                            <div v-if="item.sku.split(',')[2]">模式：{{item.sku.split(',')[2]}}</div>
                            <div v-if="item.sku.split(',')[3]">文件格式：{{item.sku.split(',')[3]}}</div>
                            <img v-if="item.fileType == 0" src="@/assets/PC/MarketingCenter/icon_ps.png" alt="">
                            <img v-else src="@/assets/PC/MarketingCenter/icon_ai.png" alt="">
                          </div>
                        </div>
                        <div class="right_right">
                          <a href="javascript:vooid(0)" v-if="item.fileUrl" @click="download(item)">
                            <img src="@/assets/PC/MarketingCenter/icon_download.png" alt="">立即下载
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="nodata" v-else>暂无数据</div>
                </div>
                <div class="paginationn" v-if="tabnav[currenttab].listdata.data.length != 0">
                  <el-pagination
                    @current-change="handleCurrentChange"
                    :total="tabnav[currenttab].listdata.recordCount"
                    :page-size="tabnav[currenttab].listdata.pageSize"
                    :current-page="tabnav[currenttab].listdata.pageIndex"
                    background
                    layout="pager, next"
                    next-text="下一页"
                    hide-on-single-page
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { debounce,GetWxShares } from '@/utils/common'
import * as MarketingCenter from '@/api/marketingCenter.js'
import { dataState } from '@/api/all.js'
import shadeAlert from "@/components/shadeAlert/index.vue"

export default {
  name: 'MarketingCenter',
  components: {
    shadeAlert,
  },
  data() {
    return {
      // !页面loading
      loading: false,
      tabnav: [],
      currenttab: 0,
      shadeConfig: {
        show: false, // 展示隐藏弹窗
        closeIcon: true, // 是否需要关闭按钮
        title: "",
        text: "", // 弹窗内容
        btnType: "", // 按钮形式
        btnList: [],
      },
    }
  },
  mounted() {
    // 微信分享
    const share = {
      title: this.$route.meta.title,
      desc: ''
    }
    GetWxShares(share);
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "MarketingCenter",
      "relationId": this.$route.query.Id
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    });
    MarketingCenter.GetMarketingCategory().then(res => {
      console.log(res)
      if(res.status == 1){
        res.data.map(item => {
          item.showfilter = false
          item.currentfilter = -1
          item.params = {
            PageSize: 5,
            PageIndex: 1,
            FirstType: item.id,
            SecondType: null
          }
          item.listdata = {}
        })
        this.tabnav = res.data
        this.GetInit(true)
        console.log(this.tabnav)
      }
      
    })
  },
  methods: {
    // !关闭登录指引弹窗
    closeAlertChange(state) {
      this.shadeConfig.show = state;
    },
    showfilterclick () {
      this.$set(this.tabnav[this.currenttab], 'showfilter', !this.tabnav[this.currenttab].showfilter)
    },
    // 下载
    download (data) {
      if(!this.$store.state.token){
        // this.$toast('请登录后操作!')
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来下载吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: "已有剑盟账号，前往登录",
              link: "/Login?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
            {
              text: "没有账号，立即注册",
              link:
                "/Register?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
          ],
        };
        return false;
      }else{
        MarketingCenter.DownloadNote({id: data.id}).then(res => {
          location.href = data.fileUrl
        })
      }
    },
    // 页数
    handleCurrentChange (currentPage) {
      this.$set(this.tabnav[this.currenttab].params, 'PageIndex', currentPage)
      this.GetInit()
    },
    GetInit (init) {
      this.loading = true
      const that = this
      if (init) {
        // 初次
        let isdone = 0
        this.tabnav.map((item, index) => {
          MarketingCenter.GetMarketingList(item.params).then(res => {
            if(res.status == 1){
              that.$set(that.tabnav[index], 'listdata', res)
              isdone ++
              if(isdone == 3){
                that.loading = false
              }
            }else{
              that.$toast(res.message)
            }
          })
        })
      } else {
        MarketingCenter.GetMarketingList(this.tabnav[this.currenttab].params).then(res => {
          that.loading = false
          if(res.status == 1){
            that.$set(this.tabnav[that.currenttab], 'listdata', res)
          }else{
            that.$toast(res.message)
          }
        })
      }
    },
    filterClick (index) {
      this.$set(this.tabnav[this.currenttab].params, 'SecondType', index != -1 ? this.tabnav[this.currenttab].children[index].id : null)
      this.$set(this.tabnav[this.currenttab], 'currentfilter', index)
      this.$set(this.tabnav[this.currenttab], 'showfilter', false)
      this.GetInit()
    },
    tabClick (index) {
      this.currenttab = index
      this.$set(this.tabnav[this.currenttab].params, 'PageIndex', 1)
      this.$set(this.tabnav[this.currenttab].params, 'SecondType', null)
      this.$set(this.tabnav[this.currenttab], 'currentfilter', -1)
      this.$set(this.tabnav[this.currenttab], 'showfilter', false)
      this.GetInit()
    },
  },
  computed: {
    ...mapState(['Infos', 'clientWidth']),
    ...mapGetters(['module']),
  },
  watch: {
    $route: {
      handler(val) {
              
        // 数据统计
        const data ={
          "visitType": 1,
          "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
          "url": window.location.href,
          "pageUrl": "MarketingCenter",
          "relationId": this.$route.query.Id
        }
        dataState(data).then(res => {
          sessionStorage.setItem('gid',res.data);
        });
      },
      // 深度观察监听
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>