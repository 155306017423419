import request from '@/utils/request'
// !获取营销分类
export function GetMarketingCategory(params) {
  return request({
    url: '/ContentCenter/GetSelect',
    method: 'GET',
    params
  })
}

// !获取营销列表
export function GetMarketingList(data) {
  return request({
    url: '/ContentCenter/GetList',
    method: 'POST',
    data
  })
}

// !下载记录
export function DownloadNote(params) {
  return request({
    url: '/ContentCenter/DownLoad',
    method: 'GET',
    params
  })
}